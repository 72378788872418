.ApprovedBy h1,
.ApprovedBy h2,
.ApprovedBy h3,
.ApprovedBy h4,
.ApprovedBy h5,
.ApprovedBy h6 {
    max-width: none;
}
@media (max-width: 768px) {
  .ApprovedBy .auth-option {
    display: block;
  }
}
.ApprovedBy .or-box {
  height: 100%;
}
.ApprovedBy .or-box .or-line{
  width: 2px;
}
.ApprovedBy .auth-options {
  text-align: center;
  min-height: 100%;
}
.ApprovedBy .auth-option {
  background: #f4f2e8;
  min-height: 100%;
  display: block;
}
.ApprovedBy .Barcode .canvas {
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
}
.ApprovedBy .Barcode .canvas,
.ApprovedBy .Barcode .outputData {
  display: none;
}
.ApprovedBy .Barcode.loaded .canvas
/*, .ApprovedBy .Barcode.loaded .outputData  */
{
  display: block;
}
